import GroupBy from 'lodash/groupBy'
import api from '@api'

export const TransformKeyword = (keyword) => {
  return {
    keyword: keyword.keyword,
    name: keyword.displayName,
    group: keyword.groupName,
    customFieldType: keyword.fieldType,
  }
}

export function getKeywordsApi(moduleName, isResponsetemplate = false) {
  return api
    .get(`${moduleName}/keywords`, {
      params: {
        isResponsetemplate,
      },
    })
    .then((data) => GroupBy(data.keywords.map(TransformKeyword), 'group'))
}

export function getServiceCatalogKeywordsApi(id, additionalParams = {}) {
  return api
    .get(`/service_catalog/${id}/keywords`, {
      params: {
        ...additionalParams,
      },
    })
    .then((data) => {
      return {
        keywords: GroupBy(data.keywords.map(TransformKeyword), 'group'),
      }
    })
}

export function getApiFieldKeywordsApi(
  moduleName = 'request',
  additionalUrlParams = {}
) {
  return api
    .get(`apifield/${moduleName}/keywords`, {
      params: {
        ...additionalUrlParams,
      },
    })
    .then((data) => {
      return {
        keywords: GroupBy(data.keywords.map(TransformKeyword), 'group'),
        options: data.supportedFields.map((k) => ({
          text: k.displayName,
          key: k.keyword,
        })),
      }
    })
}

export function getWebhookKeywordsApi(
  moduleName = 'request',
  additionalUrlParams = {}
) {
  return api
    .get(`webHook/${moduleName}/keywords`, {
      params: {
        ...additionalUrlParams,
      },
    })
    .then((data) => {
      return {
        keywords: GroupBy(data.keywords.map(TransformKeyword), 'group'),
        options: data.supportedFields.map((k) => ({
          text: k.displayName,
          key: k.keyword,
        })),
      }
    })
}
