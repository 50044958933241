import Constants from '@constants'
import { getKeywordsApi } from './api'

function getKeywordModule(moduleName) {
  const vuexModule = {
    namespaced: true,
  }
  vuexModule.state = {
    keywords: {},
  }

  vuexModule.getters = {
    keywords(state) {
      return state.keywords
    },
  }

  vuexModule.mutations = {
    SET_KEYWORDS(state, data) {
      state.keywords = data
    },
  }

  vuexModule.actions = {
    fetch({ commit }) {
      getKeywordsApi(moduleName).then((data) => {
        commit('SET_KEYWORDS', Object.freeze(data))
      })
    },

    /** fetch response template keywords */

    fetchResponseTemplateKeywords({ commit }) {
      getKeywordsApi(moduleName, true).then((data) => {
        commit('SET_KEYWORDS', Object.freeze(data))
      })
    },

    /**
     * destroy all states when user is logged out
     */
    destroy({ commit }) {
      commit('SET_KEYWORDS', {})
    },
  }
  return vuexModule
}

export const modules = {
  request: getKeywordModule(Constants.REQUEST),
  problem: getKeywordModule(Constants.PROBLEM),
  change: getKeywordModule(Constants.CHANGE),
  release: getKeywordModule(Constants.RELEASE),
  asset: getKeywordModule(Constants.ASSET),
  asset_hardware: getKeywordModule(Constants.ASSET_HARDWARE),
  asset_software: getKeywordModule(Constants.ASSET_SOFTWARE),
  asset_non_it: getKeywordModule(Constants.ASSET_NON_IT),
  asset_movement: getKeywordModule(Constants.ASSET_MOVEMENT),
  contract: getKeywordModule(Constants.CONTRACT),
  purchase: getKeywordModule(Constants.PURCHASE),
  task: getKeywordModule(Constants.TASK),
  user: getKeywordModule(Constants.USER),
  software_license: getKeywordModule(Constants.SOFTWARE_LICENSE),
  knowledge: getKeywordModule(Constants.KNOWLEDGE),
  user_survey: getKeywordModule(Constants.USER_SURVEY),
  project: getKeywordModule(Constants.PROJECT),
  auto_ticket_create: getKeywordModule(Constants.AUTO_TICKET_CREATE),
  auto_task_create: getKeywordModule(Constants.AUTO_TASK_CREATE),
}
