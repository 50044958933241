import Moment from 'moment'
import { getLicenseInformationApi } from './api'

export const state = {
  license: {},
}

export const getters = {
  license(state) {
    return state.license
  },
  isTrial(state) {
    return state.license.trial
  },
  isSuspended(state) {
    return state.license.status !== 'active'
  },
  isLicenseExpiring(state) {
    if (state.license.trial) {
      return false
    }
    const expiresAt = Moment.unix(state.license.expiresAt / 1000)
    const now = Moment().utc()
    const diffInDays = expiresAt.diff(now, 'days')
    return diffInDays <= 15 && diffInDays >= 0
  },
  isTrialExpired(state) {
    if (state.license.trial) {
      const expiresAt = Moment.unix(state.license.expiresAt / 1000)
      const now = Moment().utc()
      return now.isSameOrAfter(expiresAt)
    }
    return false
  },
  isLicenseExpired(state) {
    if (state.license.trial) {
      return false
    }
    const expiresAt = Moment.unix(state.license.expiresAt / 1000)
    const now = Moment().utc()
    return now.isSameOrAfter(expiresAt)
  },
  trialExpiresAt(state) {
    return state.license.expiresAt
  },
  availableModules(state) {
    return state.license.availableModules || []
  },
  mspEnabled(state) {
    return state.license.mspEnabled
  },
}

export const mutations = {
  SET_LICENSE(state, payload) {
    state.license = payload
  },
}

export const actions = {
  init({ dispatch }) {
    dispatch('fetch')
  },

  fetch({ commit }) {
    return getLicenseInformationApi().then((data) => {
      commit('SET_LICENSE', data)
      return Promise.resolve(data)
    })
  },

  updateLicense({ commit }, payload) {
    commit('SET_LICENSE', payload)
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    // perform state clean up here when user is logged out
    // commit('SET_LICENSE', {})
  },
}
