import { createNamespacedHelpers } from 'vuex'
import Constants from '@constants'

const {
  mapGetters: requestGetters,
  mapActions: requestActions,
} = createNamespacedHelpers(`page/${Constants.REQUEST}`)
const {
  mapGetters: problemGetters,
  mapActions: problemActions,
} = createNamespacedHelpers(`page/${Constants.PROBLEM}`)
const {
  mapGetters: changeGetters,
  mapActions: changeActions,
} = createNamespacedHelpers(`page/${Constants.CHANGE}`)
const {
  mapGetters: releaseGetters,
  mapActions: releaseActions,
} = createNamespacedHelpers(`page/${Constants.RELEASE}`)
const {
  mapGetters: taskGetters,
  mapActions: taskActions,
} = createNamespacedHelpers(`page/${Constants.TASK}`)
const {
  mapGetters: knowledgeGetters,
  mapActions: knowledgeActions,
} = createNamespacedHelpers(`page/${Constants.KNOWLEDGE}`)
const {
  mapGetters: dashboardGetters,
  mapActions: dashboardActions,
} = createNamespacedHelpers(`page/dashboard`)
const {
  mapGetters: hardwareGetters,
  mapActions: hardwareActions,
} = createNamespacedHelpers(`page/${Constants.ASSET_HARDWARE}`)
const {
  mapGetters: softwareGetters,
  mapActions: softwareActions,
} = createNamespacedHelpers(`page/${Constants.ASSET_SOFTWARE}`)
const {
  mapGetters: nonitGetters,
  mapActions: nonitActions,
} = createNamespacedHelpers(`page/${Constants.ASSET_NON_IT}`)
const {
  mapGetters: movementGetters,
  mapActions: movementActions,
} = createNamespacedHelpers(`page/${Constants.ASSET_MOVEMENT}`)
const {
  mapGetters: contractGetters,
  mapActions: contractActions,
} = createNamespacedHelpers(`page/${Constants.CONTRACT}`)
const {
  mapGetters: purchaseGetters,
  mapActions: purchaseActions,
} = createNamespacedHelpers(`page/${Constants.PURCHASE}`)
const {
  mapGetters: usersurveyGetters,
  mapActions: usersurveyActions,
} = createNamespacedHelpers(`page/${Constants.USER_SURVEY}`)
const {
  mapGetters: projectGetters,
  mapActions: projectActions,
} = createNamespacedHelpers(`page/${Constants.PROJECT}`)
const {
  mapGetters: patchGetters,
  mapActions: patchActions,
} = createNamespacedHelpers(`page/${Constants.PATCH}`)
// const {
//   mapGetters: agentGetters,
//   mapActions: agentActions,
// } = createNamespacedHelpers(`page/${Constants.AGENT}`)
const {
  mapGetters: patchComputerGetters,
  mapActions: patchComputerActions,
} = createNamespacedHelpers(`page/${Constants.PATCH_COMPUTERS}`)
const {
  mapGetters: packagesComputerGetters,
  mapActions: packagesComputerActions,
} = createNamespacedHelpers(`page/${Constants.PACKAGES_COMPUTERS}`)
const {
  mapGetters: patchRemoteDeploymentGetters,
  mapActions: patchRemoteDeploymentActions,
} = createNamespacedHelpers(`page/${Constants.PATCH_REMOTE_DEPLOYMENT}`)
const {
  mapGetters: packagesRemoteDeploymentGetters,
  mapActions: packagesRemoteDeploymentActions,
} = createNamespacedHelpers(`page/${Constants.PACKAGES_REMOTE_DEPLOYMENT}`)
const {
  mapGetters: registryRemoteDeploymentGetters,
  mapActions: registryRemoteDeploymentActions,
} = createNamespacedHelpers(`page/${Constants.REGISTRY_REMOTE_DEPLOYMENT}`)

export const PageComputed = {
  ...requestGetters({
    requestLoading: 'loading',
    requestSearches: 'searches',
    requestSelectedSearch: 'selected',
    requestSearchCriterias: 'searchCriterias',
    requestSearchDirty: 'dirty',
    requestNextItemId: 'getNextItemId',
    requestPreviousItemId: 'getPreviousItemId',
    requestPageData: 'pageData',
  }),
  ...problemGetters({
    problemLoading: 'loading',
    problemSearches: 'searches',
    problemSelectedSearch: 'selected',
    problemSearchCriterias: 'searchCriterias',
    problemSearchDirty: 'dirty',
    problemNextItemId: 'getNextItemId',
    problemPreviousItemId: 'getPreviousItemId',
    problemPageData: 'pageData',
  }),
  ...changeGetters({
    changeLoading: 'loading',
    changeSearches: 'searches',
    changeSelectedSearch: 'selected',
    changeSearchCriterias: 'searchCriterias',
    changeSearchDirty: 'dirty',
    changeNextItemId: 'getNextItemId',
    changePreviousItemId: 'getPreviousItemId',
    changePageData: 'pageData',
  }),
  ...releaseGetters({
    releaseLoading: 'loading',
    releaseSearches: 'searches',
    releaseSelectedSearch: 'selected',
    releaseSearchCriterias: 'searchCriterias',
    releaseSearchDirty: 'dirty',
    releaseNextItemId: 'getNextItemId',
    releasePreviousItemId: 'getPreviousItemId',
    releasePageData: 'pageData',
  }),
  ...taskGetters({
    taskLoading: 'loading',
    taskSearches: 'searches',
    taskSelectedSearch: 'selected',
    taskSearchCriterias: 'searchCriterias',
    taskSearchDirty: 'dirty',
    taskPageData: 'pageData',
  }),
  ...knowledgeGetters({
    knowledgeSearchCriterias: 'searchCriterias',
    knowledgeSearchDirty: 'dirty',
    knowledgePageData: 'pageData',
  }),
  ...dashboardGetters({
    dashboardPageData: 'pageData',
    dashboardSelectedSearch: 'selected',
  }),
  ...hardwareGetters({
    asset_hardwareLoading: 'loading',
    asset_hardwareSearches: 'searches',
    asset_hardwareSelectedSearch: 'selected',
    asset_hardwareSearchCriterias: 'searchCriterias',
    asset_hardwareSearchDirty: 'dirty',
    asset_hardwareNextItemId: 'getNextItemId',
    asset_hardwarePreviousItemId: 'getPreviousItemId',
    asset_hardwarePageData: 'pageData',
  }),
  ...softwareGetters({
    asset_softwareLoading: 'loading',
    asset_softwareSearches: 'searches',
    asset_softwareSelectedSearch: 'selected',
    asset_softwareSearchCriterias: 'searchCriterias',
    asset_softwareSearchDirty: 'dirty',
    asset_softwareNextItemId: 'getNextItemId',
    asset_softwarePreviousItemId: 'getPreviousItemId',
    asset_softwarePageData: 'pageData',
  }),
  ...nonitGetters({
    asset_non_itLoading: 'loading',
    asset_non_itSearches: 'searches',
    asset_non_itSelectedSearch: 'selected',
    asset_non_itSearchCriterias: 'searchCriterias',
    asset_non_itSearchDirty: 'dirty',
    asset_non_itNextItemId: 'getNextItemId',
    asset_non_itPreviousItemId: 'getPreviousItemId',
    asset_non_itPageData: 'pageData',
  }),
  ...movementGetters({
    asset_movementLoading: 'loading',
    asset_movementSearches: 'searches',
    asset_movementSelectedSearch: 'selected',
    asset_movementSearchCriterias: 'searchCriterias',
    asset_movementSearchDirty: 'dirty',
    asset_movementNextItemId: 'getNextItemId',
    asset_movementPreviousItemId: 'getPreviousItemId',
    asset_movementPageData: 'pageData',
  }),
  ...contractGetters({
    contractLoading: 'loading',
    contractSearches: 'searches',
    contractSelectedSearch: 'selected',
    contractSearchCriterias: 'searchCriterias',
    contractSearchDirty: 'dirty',
    contractNextItemId: 'getNextItemId',
    contractPreviousItemId: 'getPreviousItemId',
    contractPageData: 'pageData',
  }),
  ...purchaseGetters({
    purchaseLoading: 'loading',
    purchaseSearches: 'searches',
    purchaseSelectedSearch: 'selected',
    purchaseSearchCriterias: 'searchCriterias',
    purchaseSearchDirty: 'dirty',
    purchaseNextItemId: 'getNextItemId',
    purchasePreviousItemId: 'getPreviousItemId',
    purchasePageData: 'pageData',
  }),
  ...usersurveyGetters({
    user_surveyLoading: 'loading',
    user_surveySearches: 'searches',
    user_surveySelectedSearch: 'selected',
    user_surveySearchCriterias: 'searchCriterias',
    user_surveySearchDirty: 'dirty',
    user_surveyNextItemId: 'getNextItemId',
    user_surveyPreviousItemId: 'getPreviousItemId',
  }),
  ...projectGetters({
    projectLoading: 'loading',
    projectSearches: 'searches',
    projectSelectedSearch: 'selected',
    projectSearchCriterias: 'searchCriterias',
    projectSearchDirty: 'dirty',
    projectNextItemId: 'getNextItemId',
    projectPreviousItemId: 'getPreviousItemId',
    projectPageData: 'pageData',
  }),
  ...patchGetters({
    patchLoading: 'loading',
    patchSearches: 'searches',
    patchSelectedSearch: 'selected',
    patchSearchCriterias: 'searchCriterias',
    patchSearchDirty: 'dirty',
    patchNextItemId: 'getNextItemId',
    patchPreviousItemId: 'getPreviousItemId',
    patchPageData: 'pageData',
  }),
  // ...agentGetters({
  //   agentLoading: 'loading',
  //   agentSearches: 'searches',
  //   agentSelectedSearch: 'selected',
  //   agentSearchCriterias: 'searchCriterias',
  //   agentSearchDirty: 'dirty',
  //   agentNextItemId: 'getNextItemId',
  //   agentPreviousItemId: 'getPreviousItemId',
  //   agentPageData: 'pageData',
  // }),
  ...patchComputerGetters({
    patch_computersLoading: 'loading',
    patch_computersSearches: 'searches',
    patch_computersSelectedSearch: 'selected',
    patch_computersSearchCriterias: 'searchCriterias',
    patch_computersSearchDirty: 'dirty',
    patch_computersNextItemId: 'getNextItemId',
    patch_computersPreviousItemId: 'getPreviousItemId',
    patch_computersPageData: 'pageData',
  }),
  ...packagesComputerGetters({
    packages_computersLoading: 'loading',
    packages_computersSearches: 'searches',
    packages_computersSelectedSearch: 'selected',
    packages_computersSearchCriterias: 'searchCriterias',
    packages_computersSearchDirty: 'dirty',
    packages_computersNextItemId: 'getNextItemId',
    packages_computersPreviousItemId: 'getPreviousItemId',
    packages_computersPageData: 'pageData',
  }),
  ...patchRemoteDeploymentGetters({
    patch_remote_deploymentLoading: 'loading',
    patch_remote_deploymentSearches: 'searches',
    patch_remote_deploymentSelectedSearch: 'selected',
    patch_remote_deploymentSearchCriterias: 'searchCriterias',
    patch_remote_deploymentSearchDirty: 'dirty',
    patch_remote_deploymentNextItemId: 'getNextItemId',
    patch_remote_deploymentPreviousItemId: 'getPreviousItemId',
    patch_remote_deploymentPageData: 'pageData',
  }),
  ...packagesRemoteDeploymentGetters({
    packages_remote_deploymentLoading: 'loading',
    packages_remote_deploymentSearches: 'searches',
    packages_remote_deploymentSelectedSearch: 'selected',
    packages_remote_deploymentSearchCriterias: 'searchCriterias',
    packages_remote_deploymentSearchDirty: 'dirty',
    packages_remote_deploymentNextItemId: 'getNextItemId',
    packages_remote_deploymentPreviousItemId: 'getPreviousItemId',
    packages_remote_deploymentPageData: 'pageData',
  }),
  ...registryRemoteDeploymentGetters({
    registry_remote_deploymentLoading: 'loading',
    registry_remote_deploymentSearches: 'searches',
    registry_remote_deploymentSelectedSearch: 'selected',
    registry_remote_deploymentSearchCriterias: 'searchCriterias',
    registry_remote_deploymentSearchDirty: 'dirty',
    registry_remote_deploymentNextItemId: 'getNextItemId',
    registry_remote_deploymentPreviousItemId: 'getPreviousItemId',
    registry_remote_deploymentPageData: 'pageData',
  }),
}

export const PageMethods = {
  ...requestActions({
    requestSelectSearch: 'selectSearch',
    requestAddSearch: 'addSearch',
    requestUpdateSearch: 'updateSearch',
    requestRemoveSearch: 'removeSearch',
    requestUpdateSearchCriterias: 'updateSearchCriterias',
    requestPageReset: 'reset',
    requestSetListIds: 'setListIds',
    requestSetSearchDirty: 'setDirty',
    requestSetPageData: 'setPageData',
  }),
  ...problemActions({
    problemSelectSearch: 'selectSearch',
    problemAddSearch: 'addSearch',
    problemUpdateSearch: 'updateSearch',
    problemRemoveSearch: 'removeSearch',
    problemUpdateSearchCriterias: 'updateSearchCriterias',
    problemPageReset: 'reset',
    problemSetListIds: 'setListIds',
    problemSetSearchDirty: 'setDirty',
    problemSetPageData: 'setPageData',
  }),
  ...changeActions({
    changeSelectSearch: 'selectSearch',
    changeAddSearch: 'addSearch',
    changeUpdateSearch: 'updateSearch',
    changeRemoveSearch: 'removeSearch',
    changeUpdateSearchCriterias: 'updateSearchCriterias',
    changePageReset: 'reset',
    changeSetListIds: 'setListIds',
    changeSetSearchDirty: 'setDirty',
    changeSetPageData: 'setPageData',
  }),
  ...releaseActions({
    releaseSelectSearch: 'selectSearch',
    releaseAddSearch: 'addSearch',
    releaseUpdateSearch: 'updateSearch',
    releaseRemoveSearch: 'removeSearch',
    releaseUpdateSearchCriterias: 'updateSearchCriterias',
    releasePageReset: 'reset',
    releaseSetListIds: 'setListIds',
    releaseSetSearchDirty: 'setDirty',
    releaseSetPageData: 'setPageData',
  }),
  ...taskActions({
    taskSelectSearch: 'selectSearch',
    taskAddSearch: 'addSearch',
    taskUpdateSearch: 'updateSearch',
    taskRemoveSearch: 'removeSearch',
    taskUpdateSearchCriterias: 'updateSearchCriterias',
    taskPageReset: 'reset',
    taskSetSearchDirty: 'setDirty',
    taskSetPageData: 'setPageData',
  }),
  ...knowledgeActions({
    knowledgeUpdateSearchCriterias: 'updateSearchCriterias',
    knowledgePageReset: 'reset',
    knowledgeSetSearchDirty: 'setDirty',
    knowledgeSetPageData: 'setPageData',
  }),
  ...dashboardActions({
    dashboardSetPageData: 'setPageData',
  }),
  ...hardwareActions({
    asset_hardwareSelectSearch: 'selectSearch',
    asset_hardwareAddSearch: 'addSearch',
    asset_hardwareUpdateSearch: 'updateSearch',
    asset_hardwareRemoveSearch: 'removeSearch',
    asset_hardwareUpdateSearchCriterias: 'updateSearchCriterias',
    asset_hardwarePageReset: 'reset',
    asset_hardwareSetListIds: 'setListIds',
    asset_hardwareSetSearchDirty: 'setDirty',
    asset_hardwareSetPageData: 'setPageData',
  }),
  ...softwareActions({
    asset_softwareSelectSearch: 'selectSearch',
    asset_softwareAddSearch: 'addSearch',
    asset_softwareUpdateSearch: 'updateSearch',
    asset_softwareRemoveSearch: 'removeSearch',
    asset_softwareUpdateSearchCriterias: 'updateSearchCriterias',
    asset_softwarePageReset: 'reset',
    asset_softwareSetListIds: 'setListIds',
    asset_softwareSetSearchDirty: 'setDirty',
    asset_softwareSetPageData: 'setPageData',
  }),
  ...nonitActions({
    asset_non_itSelectSearch: 'selectSearch',
    asset_non_itAddSearch: 'addSearch',
    asset_non_itUpdateSearch: 'updateSearch',
    asset_non_itRemoveSearch: 'removeSearch',
    asset_non_itUpdateSearchCriterias: 'updateSearchCriterias',
    asset_non_itPageReset: 'reset',
    asset_non_itSetListIds: 'setListIds',
    asset_non_itSetSearchDirty: 'setDirty',
    asset_non_itSetPageData: 'setPageData',
  }),
  ...movementActions({
    asset_movementSelectSearch: 'selectSearch',
    asset_movementAddSearch: 'addSearch',
    asset_movementUpdateSearch: 'updateSearch',
    asset_movementRemoveSearch: 'removeSearch',
    asset_movementUpdateSearchCriterias: 'updateSearchCriterias',
    asset_movementPageReset: 'reset',
    asset_movementSetListIds: 'setListIds',
    asset_movementSetSearchDirty: 'setDirty',
    asset_movementSetPageData: 'setPageData',
  }),
  ...contractActions({
    contractSelectSearch: 'selectSearch',
    contractAddSearch: 'addSearch',
    contractUpdateSearch: 'updateSearch',
    contractRemoveSearch: 'removeSearch',
    contractUpdateSearchCriterias: 'updateSearchCriterias',
    contractPageReset: 'reset',
    contractSetListIds: 'setListIds',
    contractSetSearchDirty: 'setDirty',
    contractSetPageData: 'setPageData',
  }),
  ...purchaseActions({
    purchaseSelectSearch: 'selectSearch',
    purchaseAddSearch: 'addSearch',
    purchaseUpdateSearch: 'updateSearch',
    purchaseRemoveSearch: 'removeSearch',
    purchaseUpdateSearchCriterias: 'updateSearchCriterias',
    purchasePageReset: 'reset',
    purchaseSetListIds: 'setListIds',
    purchaseSetSearchDirty: 'setDirty',
    purchaseSetPageData: 'setPageData',
  }),

  ...usersurveyActions({
    user_surveySelectSearch: 'selectSearch',
    user_surveyAddSearch: 'addSearch',
    user_surveyUpdateSearch: 'updateSearch',
    user_surveyRemoveSearch: 'removeSearch',
    user_surveyUpdateSearchCriterias: 'updateSearchCriterias',
    user_surveyPageReset: 'reset',
    user_surveySetListIds: 'setListIds',
    user_surveySetSearchDirty: 'setDirty',
  }),
  ...projectActions({
    projectSelectSearch: 'selectSearch',
    projectAddSearch: 'addSearch',
    projectUpdateSearch: 'updateSearch',
    projectRemoveSearch: 'removeSearch',
    projectUpdateSearchCriterias: 'updateSearchCriterias',
    projectPageReset: 'reset',
    projectSetListIds: 'setListIds',
    projectSetSearchDirty: 'setDirty',
    projectSetPageData: 'setPageData',
  }),
  ...patchActions({
    patchSelectSearch: 'selectSearch',
    patchAddSearch: 'addSearch',
    patchUpdateSearch: 'updateSearch',
    patchRemoveSearch: 'removeSearch',
    patchUpdateSearchCriterias: 'updateSearchCriterias',
    patchPageReset: 'reset',
    patchSetListIds: 'setListIds',
    patchSetSearchDirty: 'setDirty',
    patchSetPageData: 'setPageData',
  }),
  // ...agentActions({
  //   agentSelectSearch: 'selectSearch',
  //   agentAddSearch: 'addSearch',
  //   agentUpdateSearch: 'updateSearch',
  //   agentRemoveSearch: 'removeSearch',
  //   agentUpdateSearchCriterias: 'updateSearchCriterias',
  //   agentPageReset: 'reset',
  //   agentSetListIds: 'setListIds',
  //   agentSetSearchDirty: 'setDirty',
  //   agentSetPageData: 'setPageData',
  // }),
  ...patchComputerActions({
    patch_computersSelectSearch: 'selectSearch',
    patch_computersAddSearch: 'addSearch',
    patch_computersUpdateSearch: 'updateSearch',
    patch_computersRemoveSearch: 'removeSearch',
    patch_computersUpdateSearchCriterias: 'updateSearchCriterias',
    patch_computersPageReset: 'reset',
    patch_computersSetListIds: 'setListIds',
    patch_computersSetSearchDirty: 'setDirty',
    patch_computersSetPageData: 'setPageData',
  }),
  ...packagesComputerActions({
    packages_computersSelectSearch: 'selectSearch',
    packages_computersAddSearch: 'addSearch',
    packages_computersUpdateSearch: 'updateSearch',
    packages_computersRemoveSearch: 'removeSearch',
    packages_computersUpdateSearchCriterias: 'updateSearchCriterias',
    packages_computersPageReset: 'reset',
    packages_computersSetListIds: 'setListIds',
    packages_computersSetSearchDirty: 'setDirty',
    packages_computersSetPageData: 'setPageData',
  }),
  ...patchRemoteDeploymentActions({
    patch_remote_deploymentSelectSearch: 'selectSearch',
    patch_remote_deploymentAddSearch: 'addSearch',
    patch_remote_deploymentUpdateSearch: 'updateSearch',
    patch_remote_deploymentRemoveSearch: 'removeSearch',
    patch_remote_deploymentUpdateSearchCriterias: 'updateSearchCriterias',
    patch_remote_deploymentPageReset: 'reset',
    patch_remote_deploymentSetListIds: 'setListIds',
    patch_remote_deploymentSetSearchDirty: 'setDirty',
    patch_remote_deploymentSetPageData: 'setPageData',
  }),
  ...packagesRemoteDeploymentActions({
    packages_remote_deploymentSelectSearch: 'selectSearch',
    packages_remote_deploymentAddSearch: 'addSearch',
    packages_remote_deploymentUpdateSearch: 'updateSearch',
    packages_remote_deploymentRemoveSearch: 'removeSearch',
    packages_remote_deploymentUpdateSearchCriterias: 'updateSearchCriterias',
    packages_remote_deploymentPageReset: 'reset',
    packages_remote_deploymentSetListIds: 'setListIds',
    packages_remote_deploymentSetSearchDirty: 'setDirty',
    packages_remote_deploymentSetPageData: 'setPageData',
  }),
  ...registryRemoteDeploymentActions({
    registry_remote_deploymentSelectSearch: 'selectSearch',
    registry_remote_deploymentAddSearch: 'addSearch',
    registry_remote_deploymentUpdateSearch: 'updateSearch',
    registry_remote_deploymentRemoveSearch: 'removeSearch',
    registry_remote_deploymentUpdateSearchCriterias: 'updateSearchCriterias',
    registry_remote_deploymentPageReset: 'reset',
    registry_remote_deploymentSetListIds: 'setListIds',
    registry_remote_deploymentSetSearchDirty: 'setDirty',
    registry_remote_deploymentSetPageData: 'setPageData',
  }),
}
