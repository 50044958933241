import store from '@state/store'

export function token() {
  return store.state.auth.token && store.state.auth.token.access_token
}
export function isLoggedIn() {
  return Boolean(store.state.auth.user)
}
export function isPortalLogin() {
  return store.state.auth.loginType === 'support-portal'
}
export function logout() {
  return store.dispatch('auth/logout')
}

export function getCurrentUser() {
  return store.state.auth.user || {}
}
