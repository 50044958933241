import Moment from 'moment'
import ApiClient from '@api'
import dispatchActionForAllModules from '@utils/dispatch-action-for-all-modules'
import { updateApplicationLocale, getCurrentI18nLanguage } from '@src/i18n'
import { LANGUAGE_MAP } from '@utils/language-map'
import { loginApi, logoutApi, getUserApi } from './api'

export const state = {
  user: getSavedState('auth.user'),
  token: getSavedState('auth.token'),
  loginType: getSavedState('auth.loginType') || 'support-portal',
  twoFactorAuthToken: getSavedState('auth.twoFactorAuthToken'),
  isRtl: false,
}

export const mutations = {
  SET_AUTHENTICATED_USER(state, newValue) {
    state.user = newValue
    saveState('auth.user', newValue)
  },
  SET_TOKEN(state, newValue) {
    state.token = newValue
    saveState('auth.token', newValue)
    setDefaultAuthHeaders(state)
  },
  SET_LOGIN_TYPE(state, newValue) {
    state.loginType = newValue
    saveState('auth.loginType', newValue)
  },
  SET_TWO_FACTORE_AUTH_TOKEN(state, newValue) {
    state.twoFactorAuthToken = newValue
    saveState('auth.twoFactorAuthToken', newValue)
  },
}

export const getters = {
  // Whether the user is currently logged in.
  isPortalLogin(state) {
    return state.loginType === 'support-portal'
  },
  loginType(state) {
    return state.loginType
  },
  loggedIn(state) {
    return !!state.user
  },
  accessToken(state) {
    return state.token && state.token.access_token
  },
  twoFactorAuthToken(state) {
    return state.twoFactorAuthToken && state.twoFactorAuthToken
  },
  isRtl(state) {
    if (state.user) {
      const lang = LANGUAGE_MAP[state.user.userLanguage]
      return ['ar'].indexOf(lang) >= 0
    }
    return false
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch }) {
    setDefaultAuthHeaders(state)
    dispatch('validate')
  },

  // Logs in the current user.
  login({ commit, dispatch, getters }, { username, password, loginType } = {}) {
    if (getters.loggedIn) return dispatch('validate')
    return loginApi(username, password)
      .then((token) => {
        commit('SET_TOKEN', token)
        commit('SET_LOGIN_TYPE', loginType)
        if (token.uarToken) {
          return {
            resetToken: token.uarToken,
          }
        } else {
          return getUserApi()
        }
      })
      .then((user) => {
        if (user.id) {
          commit('SET_AUTHENTICATED_USER', user)
        }
        return user
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          commit('SET_AUTHENTICATED_USER', null)
          commit('SET_TOKEN', null)
        }
        return null
      })
  },

  changeLoginType({ commit }, type) {
    commit('SET_LOGIN_TYPE', type)
  },

  setToken({ commit }, token) {
    commit('SET_TOKEN', token)
  },

  setTwoFcatorAuthToken({ commit }, token) {
    commit('SET_TWO_FACTORE_AUTH_TOKEN', token)
  },

  getUser({ commit }, params = { pageReload: false }) {
    return getUserApi().then((user) => {
      commit('SET_AUTHENTICATED_USER', user)
      const lang = LANGUAGE_MAP[user.userLanguage]
      if (getCurrentI18nLanguage() !== lang) {
        Moment.locale(lang)
        updateApplicationLocale(lang).then(() => {
          if (params.pageReload) {
            window.location.reload()
          }
        })
      }
      return user
    })
  },

  // Logs out the current user.
  logout({ commit }) {
    logoutApi()
    commit('SET_AUTHENTICATED_USER', null)
    commit('SET_TOKEN', null)
    commit('SET_LOGIN_TYPE', 'support-portal')
    dispatchActionForAllModules('destroy', { exclude: ['auth', 'ssoConfig'] })
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  validate({ commit, state, dispatch }) {
    if (!state.user) return Promise.resolve(null)
    if (state.user && state.token && state.token.expires_at > Moment().unix()) {
      return Promise.resolve(state.user)
    }
    dispatch('logout')
    return Promise.resolve(null)
    // @TODO replace this with refresh token mechanism
    // return new Promise((resolve) => {
    //   return getUserApi()
    //     .then((user) => {
    //       commit('SET_AUTHENTICATED_USER', user)
    //       resolve(user)
    //     })
    //     .catch(() => {
    //       // @TODO handle refresh token once
    //       dispatch('logout')
    //       resolve(null)
    //     })
    // })
  },
}

// ===
// Private helpers
// ===

export function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

function setDefaultAuthHeaders(state) {
  if (state.token && state.token.access_token) {
    ApiClient.setHeaders({
      Authorization: `Bearer ${state.token.access_token}`,
    })
  } else {
    ApiClient.removeHeader('Authorization')
  }
}
