// import { isLoggedIn } from '@utils/auth'
import Constants from '@constants'
import { requiredNoteRulesApi } from './api'

function createCustomRulesModule(moduleName) {
  const vuexModule = {
    namespaced: true,
  }
  vuexModule.state = {
    fields: [],
  }

  vuexModule.getters = {
    fields(state) {
      return state.fields
    },
    fieldRequiresNote(state) {
      return (field) => state.fields.indexOf(field) >= 0
    },
  }

  vuexModule.mutations = {
    SET_FIELDS(state, fields) {
      state.fields = fields
    },
  }

  vuexModule.actions = {
    init({ dispatch }) {
      // if (isLoggedIn()) {
      //   dispatch('fetch')
      // }
    },
    fetch({ commit }) {
      // perform any api request which should be called only when user is logged in
      requiredNoteRulesApi(moduleName).then((data) => {
        commit(
          'SET_FIELDS',
          data.items.map((i) => `${i}`)
        )
      })
    },

    /**
     * destroy all states when user is logged out
     */
    destroy({ commit }) {
      // perform state clean up here when user is logged out
      commit('SET_FIELDS', [])
    },
  }

  return vuexModule
}

export const modules = {
  request: createCustomRulesModule(Constants.REQUEST),
  problem: createCustomRulesModule(Constants.PROBLEM),
  change: createCustomRulesModule(Constants.CHANGE),
  release: createCustomRulesModule(Constants.RELEASE),
  purchase: createCustomRulesModule(Constants.PURCHASE),
  project: createCustomRulesModule(Constants.PROJECT),
}
