import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('organization')

export const OrganizationComputed = {
  ...mapGetters([
    'organization',
    'defaultTimeZone',
    'organizationIsRtl',
    'multiTenantEnabled',
    'multiTenantEnvironment',
  ]),
}

export const OrganizationMethods = mapActions({
  fetchOrganization: 'fetch',
  updateOrganization: 'updateOrganization',
})
