import Moment from 'moment'
import Datetime from '@src/filters/datetime'
import Duration from '@src/filters/duration'
import Bytes from '@src/filters/bytes'
import Hertz from '@src/filters/hertz'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
const __t = getRootTranslator()
const __tc = getRootPluaralTranslator()

export function convertValueForDescription(inputType, value, field = {}) {
  if (['datetime'].indexOf(inputType) >= 0) {
    value = Datetime(value, undefined, (field.attributes || {}).allowTime)
  }
  if (['dateTime'].indexOf(inputType) >= 0) {
    // convert value to date time
    value = Datetime(value)
  }
  if (['date'].indexOf(inputType) >= 0) {
    // convert value to date
    value = Datetime(value, 'ddd, MMM DD, YYYY', false)
  }
  if (['estimatedTime'].indexOf(inputType) >= 0) {
    const hours = Moment.duration(Math.abs(value), 'milliseconds').asHours()
    value = `${hours} ${__tc('hours', hours)}`
  }
  if (inputType === 'boolean') {
    value = value ? __t('yes') : __t('no')
  }
  if (['tags', 'emails', 'checkbox'].indexOf(inputType) >= 0) {
    return (value || []).join(', ')
  }
  if (['bytes'].indexOf(inputType) >= 0) {
    value = Bytes(value, field.unit)
  }
  if (['hertz'].indexOf(inputType) >= 0) {
    value = Hertz(value, field.unit)
  }
  if (['timePicker'].indexOf(inputType) >= 0) {
    value = Duration(value)
  }
  return value
}

export const getDropdownItemValue = (item) => {
  if ('key' in item) {
    return item.key
  }
  if ('value' in item) {
    return item.value
  }
  if ('id' in item) {
    return item.id
  }
}
