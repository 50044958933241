import { getUserSecurityApi } from './api'
export const state = {
  config: {},
}

export const getters = {
  lockUserAccount(state) {
    return state.config.lockUserAccount
  },
  loginAttempts(state) {
    return state.config.loginAttempts
  },
  sessionIdleTimeOutUnit(state) {
    return state.config.sessionIdleTimeOutUnit
  },
  sessionTimeOutUnit(state) {
    return state.config.sessionTimeOutUnit
  },
  enbleSessionTimeout(state) {
    return state.config.enbleSessionTimeout
  },
  sessionIdleTimeOut(state) {
    return state.config.sessionIdleTimeOut
  },
  sessionTimeOut(state) {
    return state.config.sessionTimeOut
  },
  disabledConcurrentLoggedIn(state) {
    return state.config.disabledConcurrentLoggedIn
  },
  enableCaptcha(state) {
    return state.config.enableCaptcha
  },
  captchaType(state) {
    return state.config.captchaType
  },
  site(state) {
    return state.config.site
  },
  secret(state) {
    return state.config.secret
  },
  enableCaptchaForGuestUser(state) {
    return state.config.enableCaptchaForGuestUser
  },
  enabledGoogleLogin(state) {
    return state.config.enabledGoogleLogin
  },
  googleWebClientId(state) {
    return state.config.googleWebClientId
  },
}

export const mutations = {
  SET_CONFIG(state, payload) {
    state.config = payload
  },
}

export const actions = {
  init({ dispatch }) {
    dispatch('fetch')
  },

  fetch({ commit }) {
    return getUserSecurityApi().then((data) => {
      commit('SET_CONFIG', data)
      return data
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    // perform state clean up here when user is logged out
  },
}
