import { createNamespacedHelpers } from 'vuex'
import Constants from '@constants'

const {
  mapGetters: requestGetters,
  mapActions: requestActions,
} = createNamespacedHelpers(`customRules/${Constants.REQUEST}`)
const {
  mapGetters: problemGetters,
  mapActions: problemActions,
} = createNamespacedHelpers(`customRules/${Constants.PROBLEM}`)
const {
  mapGetters: changeGetters,
  mapActions: changeActions,
} = createNamespacedHelpers(`customRules/${Constants.CHANGE}`)
const {
  mapGetters: releaseGetters,
  mapActions: releaseActions,
} = createNamespacedHelpers(`customRules/${Constants.RELEASE}`)
const {
  mapGetters: purchaseGetters,
  mapActions: purchaseActions,
} = createNamespacedHelpers(`customRules/${Constants.PURCHASE}`)
const {
  mapGetters: projectGetters,
  mapActions: projectActions,
} = createNamespacedHelpers(`customRules/${Constants.PROJECT}`)

export const CustomRulesComputed = {
  ...requestGetters({
    requestNoteRequiredFields: 'fields',
    requestIsFieldRequiredNote: 'fieldRequiresNote',
  }),
  ...problemGetters({
    problemNoteRequiredFields: 'fields',
    problemIsFieldRequiredNote: 'fieldRequiresNote',
  }),
  ...changeGetters({
    changeNoteRequiredFields: 'fields',
    changeIsFieldRequiredNote: 'fieldRequiresNote',
  }),
  ...releaseGetters({
    releaseNoteRequiredFields: 'fields',
    releaseIsFieldRequiredNote: 'fieldRequiresNote',
  }),
  ...purchaseGetters({
    purchaseNoteRequiredFields: 'fields',
    purchaseIsFieldRequiredNote: 'fieldRequiresNote',
  }),
  ...projectGetters({
    projectNoteRequiredFields: 'fields',
    projectIsFieldRequiredNote: 'fieldRequiresNote',
  }),
}

export const CustomRulesMethods = {
  ...requestActions({
    requestFetchNoteRules: 'fetch',
  }),
  ...problemActions({
    problemFetchNoteRules: 'fetch',
  }),
  ...changeActions({
    changeFetchNoteRules: 'fetch',
  }),
  ...releaseActions({
    releaseFetchNoteRules: 'fetch',
  }),
  ...purchaseActions({
    purchaseFetchNoteRules: 'fetch',
  }),
  ...projectActions({
    projectFetchNoteRules: 'fetch',
  }),
}
