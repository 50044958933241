import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters: priorityGetters,
  mapActions: priorityActions,
} = createNamespacedHelpers('priority')

export const PriorityComputed = {
  ...priorityGetters(['priorityOptions']),
}

export const PriorityActions = {
  ...priorityActions({
    fetchPriority: 'fetch',
  }),
}
