import { createNamespacedHelpers } from 'vuex'
import Constants from '@constants'

const {
  mapGetters: requestGetters,
  mapActions: requestActions,
} = createNamespacedHelpers(`status/${Constants.REQUEST}`)
const {
  mapGetters: problemGetters,
  mapActions: problemActions,
} = createNamespacedHelpers(`status/${Constants.PROBLEM}`)
const {
  mapGetters: changeGetters,
  mapActions: changeActions,
} = createNamespacedHelpers(`status/${Constants.CHANGE}`)
const {
  mapGetters: releaseGetters,
  mapActions: releaseActions,
} = createNamespacedHelpers(`status/${Constants.RELEASE}`)
const {
  mapGetters: taskGetters,
  mapActions: taskActions,
} = createNamespacedHelpers(`status/${Constants.TASK}`)
const {
  mapGetters: assetGetters,
  mapActions: assetActions,
} = createNamespacedHelpers(`status/${Constants.ASSET}`)
const {
  mapGetters: purchaseGetters,
  mapActions: purchaseActions,
} = createNamespacedHelpers(`status/${Constants.PURCHASE}`)

export const StatusComputed = {
  ...requestGetters({
    requestFindStatus: 'findStatus',
    requestStatus: 'status',
  }),
  ...problemGetters({
    problemFindStatus: 'findStatus',
    problemStatus: 'status',
  }),
  ...changeGetters({
    changeFindStatus: 'findStatus',
    changeFindStatusFromId: 'findStatusFromId',
    changeStatus: 'changeStatus',
  }),
  ...releaseGetters({
    releaseFindStatus: 'findStatus',
    releaseFindStatusFromId: 'findStatusFromId',
    releaseStatus: 'releaseStatus',
  }),
  ...taskGetters({
    taskFindStatus: 'findStatus',
    taskStatus: 'status',
  }),
  ...assetGetters({
    assetFindStatus: 'findStatus',
    assetStatus: 'status',
  }),
  ...purchaseGetters({
    purchaseFindStatus: 'findStatus',
    purchaseFindStatusFromId: 'findStatusFromId',
    purchaseStatus: 'status',
  }),
}

export const StatusActions = {
  ...requestActions({
    fetchRequestStatus: 'fetch',
  }),
  ...problemActions({
    fetchProblemStatus: 'fetch',
  }),
  ...changeActions({
    fetchChangeStatus: 'fetch',
  }),
  ...releaseActions({
    fetchReleaseStatus: 'fetch',
  }),
  ...taskActions({
    fetchTaskStatus: 'fetch',
  }),
  ...assetActions({
    fetchAssetStatus: 'fetch',
  }),
  ...purchaseActions({
    fetchPurchaseStatus: 'fetch',
  }),
}
