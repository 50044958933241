import api from '@api'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformSsoConfig,
  transformSsoConfigForServer,
} from '@data/sso-config'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function getSsoConfigApi(usePublic = false) {
  return api
    .get(`${usePublic ? '/public/' : '/'}ssoConfig`)
    .then(transformSsoConfig)
}

export function updateSsoConfigApi(data) {
  return api
    .patch(`/ssoConfig`, transformSsoConfigForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('sso_configuration'),
      }),
    })
    .then(transformSsoConfig)
}
