import { fetchTechniciansApi } from './api'
import { isLoggedIn } from '@utils/auth'
import { getRootTranslator } from '@utils/get-module-translator'
const _tc = getRootTranslator()

export const state = {
  users: [],
  total: null,
}

export const getters = {
  technicianOptions(state) {
    return [
      {
        key: 0,
        id: 0,
        name: _tc('unassigned'),
        email: null,
        avatar: 'user',
        avatarType: 'icon',
        isTenant: false,
      },
    ].concat(
      state.users.map((u) => ({
        key: u.id,
        id: u.id,
        name: u.name,
        email: u.email,
        avatar: u.avatar,
        loadIndicator: u.loadIndicator || 'low',
        groups: u.groups,
        isTenant: u.isTenant,
        archived: u.archived,
        blocked: u.blocked,
        allowToLogin: u.allowToLogin,
        isSuperAdmin: u.isSuperAdmin,
        superAdminRole: u.superAdminRole,
      }))
    )
  },
  technician(state) {
    return (id) => {
      return state.users.find((t) => t.id === id) || {}
    }
  },
}

export const mutations = {
  SET_USERS(state, data) {
    state.users = data
  },
  SET_TOTAL(state, total) {
    state.total = total
  },
}

export const actions = {
  init({ dispatch }) {
    if (isLoggedIn()) {
      dispatch('fetch')
    }
  },

  fetch({ commit }, params) {
    return fetchTechniciansApi(params).then(({ users, total }) => {
      commit('SET_USERS', users)
      commit('SET_TOTAL', total)
    })
  },

  destroy({ commit }) {
    commit('SET_USERS', [])
    commit('SET_TOTAL', null)
  },
}
