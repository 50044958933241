import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('userSecurityConfig')

export const UserSecurityConfigComputed = {
  ...mapGetters({
    enabled2fa: 'enabled2fa',
    lockUserAccountOnFailedLogin: 'lockUserAccount',
    loginAttempts: 'loginAttempts',
    sessionIdleTimeOutUnit: 'sessionIdleTimeOutUnit',
    sessionTimeOutUnit: 'sessionTimeOutUnit',
    enabledSessionTimeout: 'enbleSessionTimeout',
    sessionIdleTimeOut: 'sessionIdleTimeOut',
    sessionTimeOut: 'sessionTimeOut',
    disabledConcurrentLoggedIn: 'disabledConcurrentLoggedIn',
    enableCaptcha: 'enableCaptcha',
    captchaType: 'captchaType',
    site: 'site',
    secret: 'secret',
    enableCaptchaForGuestUser: 'enableCaptchaForGuestUser',
    enabledGoogleLogin: 'enabledGoogleLogin',
    googleWebClientId: 'googleWebClientId',
  }),
}

export const UserSecurityConfigMethods = mapActions({
  fetchUserSecurityConfigs: 'fetch',
})
