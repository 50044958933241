import Invert from 'lodash/invert'
import api from '@api'
import Constants from '@constants'
import { isLoggedIn } from '@utils/auth'

const ModuleCodesMap = {
  [Constants.REQUEST]: 100,
  [Constants.SERVICE_CATALOG]: 200,
  [Constants.PROBLEM]: 300,
  [Constants.CHANGE]: 400,
  [Constants.ASSET]: 500,
  [Constants.CONTRACT]: 600,
  [Constants.RELEASE]: 700,
  [Constants.PURCHASE]: 800,
  [Constants.PROJECT]: 900,
  [Constants.PATCH]: 1000,
  [Constants.DEPLOYMENT]: 1100,
  customDashboard: 901,
  customReport: 902,
  customSLA: 903,
  customBusinessHour: 904,
  customMultipleEmailConfig: 905,
}

const getAdjustedCodes = (codes, exclude = []) => {
  const excludedCodes = exclude.map((x) => ModuleCodesMap[x]).filter(Boolean)
  return codes.filter((c) => excludedCodes.indexOf(c) === -1)
}

export function getLicenseInformationApi() {
  return api
    .get(`${isLoggedIn() ? '/' : '/public/'}tenant/licenseCode`, {
      notify: false,
    })
    .then((data) => {
      const availableModules = []
      const InvertMap = Invert(ModuleCodesMap)
      getAdjustedCodes(data.codes, []).forEach((code) => {
        if (InvertMap[code]) {
          // @TODO temporary add product and vendor if asset module available
          if (InvertMap[code] === Constants.ASSET) {
            availableModules.push(Constants.PRODUCT, Constants.VENDOR)
          }
          if (
            [Constants.REQUEST, Constants.PROBLEM, Constants.CHANGE].indexOf(
              InvertMap[code]
            ) >= 0
          ) {
            availableModules.push(Constants.TASK)
          }
          availableModules.push(InvertMap[code])
        }
      })
      return {
        availableModules,
        status: data.status,
        trial: data.licenseType === 'trial',
        usedTechnicians: data.usedTechnicianCount,
        allowedTechnicians: data.technicianCount,
        otherAssetCount: data.otherAssetCount,
        hwAssetCount: data.hwAssetCount,
        appVersion: data.appVersion,
        usedItAsset: data.usedItAssets,
        usedNonItAsset: data.usedNonItAssets,
        expiresAt: data.expiryDate,
        plan: data.planName,
        noOfTenant: data.noOfTenant,
        ownerName: data.ownerName,
        issueDate: data.issueDate,
        userLicenseType: data.userLicenseType,
        endpointScopeCount: data.endpointScopeCount,
        usedEndpointScope: data.usedEndpointScope,
        assignedTechnicianCount: data.assignedTechnicianCount,
        assignedHwAssetCount: data.assignedHwAssetCount,
        assignedOtherAssetCount: data.assignedOtherAssetCount,
        assignedEndpointScopeCount: data.assignedEndpointScopeCount,
        noOfUsedTenant: data.noOfUsedTenant,
        defaultTenant: data.defaultTenant,
        mspEnabled: data.mspEnabled,
      }
    })
}
